import { getConfig, getConfigValue } from './config';

const clientConfig = getConfig('client');

const policy = (nonce?: string) => ({
	'base-uri': ["'self'"],
	'connect-src': [
		"'self'",
		clientConfig['API_URL'].replace(/\/api$/, ''),
		clientConfig['CDN'],
		'https://fonts.bunny.net',
		'https://*.sentry.io',
		'https://*.mapbox.com',
		'https://api.stripe.com',
		'https://formspree.io',
		clientConfig['ENVIRONMENT'] === 'production'
			? 'https://token-pay.nbcsportsnext.io/api/v1/paymentmethod/store/card'
			: 'https://rc2-token-pay.nbcsportsnext.io/api/v1/paymentmethod/store/card',
		clientConfig['ENVIRONMENT'] === 'development' ? 'wss://localhost.troon.com:*' : false,

		'https://www.googletagmanager.com', // GTM
		'https://www.google.com/ccm/', // GTM
		'https://google.com/pagead/', // GTM
		'https://google.com/ccm/', // GTM
		'https://connect.facebook.net', // GTM (Meta pixel)
		'https://www.facebook.com/tr/', // GTM (Meta)
		'https://px.ads.linkedin.com', // GTM (linkedin)
		'https://n.clarity.ms/collect', // GTM (bing)

		// Google Actions Center https://developers.google.com/actions-center/verticals/appointments/redirect/integration-steps/conversion-tracking
		getConfigValue('GOOGLE_ACTIONS_ENDPOINT'),
	],
	'default-src': ["'self'", "'unsafe-inline'"],
	'form-action': [
		"'self'",
		'https://www.facebook.com/tr/', // GTM (Meta)
	],
	'font-src': [
		"'self'",
		'https://fonts.bunny.net',

		// GTM Preview mode https://developers.google.com/tag-platform/security/guides/csp
		clientConfig['ENVIRONMENT'] !== 'production' && clientConfig['PIXEL_GTM'] ? 'https://fonts.gstatic.com' : false,
	],
	'frame-src': [
		"'self'",
		'https://*.stripe.com',
		'https://www.youtube-nocookie.com',
		'https://player.vimeo.com',
		'https://*.visammg.com', // VISA + Troon Rewards
		'https://td.doubleclick.net', // GTM (gtag.js)
		'https://www.googletagmanager.com', // GTM
		'https://www.facebook.com/', // GTM (meta)
	],
	'img-src': [
		"'self'",
		'data:',
		clientConfig['IMAGE_HOST'],
		clientConfig['CDN'],
		`https://${clientConfig['MAP_HOST']}`,
		`https://images.ctfassets.net/${getConfigValue('CONTENTFUL_SPACEID')}/`,

		'https://www.googletagmanager.com', // GTM
		'https://www.google.com/pagead/', // GTM
		// GTM Preview mode https://developers.google.com/tag-platform/security/guides/csp
		...(clientConfig['ENVIRONMENT'] !== 'production' && clientConfig['PIXEL_GTM']
			? [
					'https://googletagmanager.com',
					'https://ssl.gstatic.com',
					'https://www.gstatic.com',
					'https://fonts.gstatic.com',
				]
			: []),
		'https://www.facebook.com', // gtm
		'https://px.ads.linkedin.com', // GTM (linkedin)'
		'https://c.bing.com', // GTM (bing)
		'https://bat.bing.com/action/', // GTM (bing)
		'https://c.clarity.ms', // GTM (bing)
	],
	'media-src': ['blob:', clientConfig['CDN']],
	'object-src': ["'none'"],
	'report-to': ['csp-endpoint'],
	'report-uri': [clientConfig['SENTRY_REPORT_URI']],
	'style-src': [
		"'self'",
		clientConfig['CDN'],
		// In dev, the <style> element for Tailwind is replaced when it changes – and doesn't include the nonce
		clientConfig['ENVIRONMENT'] === 'development' || !nonce ? "'unsafe-inline'" : `'nonce-${nonce}'`,
	],
	'style-src-attr': ["'self'", "'unsafe-inline'", clientConfig['CDN']],
	'style-src-elem': [
		"'self'",
		clientConfig['CDN'],
		// GTM Preview mode https://developers.google.com/tag-platform/security/guides/csp
		...(clientConfig['ENVIRONMENT'] !== 'production' && clientConfig['PIXEL_GTM']
			? ['https://www.googletagmanager.com', 'https://fonts.googleapis.com', "'unsafe-inline'"]
			: []),
		// In dev, the <style> element for Tailwind is replaced when it changes – and doesn't include the nonce
		clientConfig['ENVIRONMENT'] === 'development' || !nonce ? "'unsafe-inline'" : `'nonce-${nonce}'`,
	],
	'script-src': [
		"'self'",
		'https://*.stripe.com', // Stripe payments

		'https://www.googletagmanager.com', // GTM
		'https://connect.facebook.net', // GTM (meta pixel)
		// GTM Preview mode https://developers.google.com/tag-platform/security/guides/csp
		...(clientConfig['ENVIRONMENT'] !== 'production' && clientConfig['PIXEL_GTM']
			? ['https://googletagmanager.com', 'https://tagmanager.google.com']
			: []),

		clientConfig['CDN'],
		...(!nonce ? ["'unsafe-inline'"] : [`'nonce-${nonce}'`, "'strict-dynamic'"]),
	],
	'worker-src': ["'self'", 'blob:'],
});

export function getContentSecurityPolicy(nonce?: string) {
	const csp: Array<string> = [];
	for (const [directive, values] of Object.entries(policy(nonce))) {
		csp.push(`${directive} ${values.filter(Boolean).join(' ')}`);
	}
	return csp.join('; ');
}
